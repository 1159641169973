import React from "react";
import { Container } from "reactstrap";
import Layout from "../../reusable/layout/Layout";


//Components
import SwapBanner from '../../components/swap/SwapBanner';

const Swap = () => {

  return (
    <Layout>
      <section id="Road-map">
        <Container className="h-100">
          <SwapBanner/>
        </Container>
      </section>
    </Layout>
  );
};

export default Swap;
