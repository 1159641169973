import React from "react";
import { Container, Row, Col } from "reactstrap";
import { useLocation } from "react-router-dom";

//Logos
import logo from "../../assets/logo/stellarlogo.png";
//import phantasma from "../../assets/logo/Powered by Phantasma1.png";
import ISS from "../../assets/logo/ISSWhiteOnBlack.png";

const Footer = () => {
  const location = useLocation();

  const handleContentChange = () => {
    // Obtener la ruta actual
    const currentPath = location.pathname;

    // Condicionalmente cambiar el contenido basado en la ruta actual
    if (currentPath === "/") {
      return (
        <>
          <div className="link-wrapper">
            <li>
              <a href="#Banner"> Banner</a>
            </li>
          </div>
          <div className="link-wrapper">
            <li>
              <a href="#Future">Future</a>
            </li>
          </div>
          <div className="link-wrapper">
            <li>
              <a href="#Projects">Projects</a>
            </li>
          </div>
          <div className="link-wrapper">
            <li>
              <a href="#Experience">Experience</a>
            </li>
          </div>
          <div className="link-wrapper">
            <li>
              <a href="#Play">Play with us</a>
            </li>
          </div>
          <div className="link-wrapper">
            <li>
              <a href="#JoinUs">Join Us</a>
            </li>
          </div>
        </>
      );
    } else if (currentPath === "/roadmap") {
      return (
        <>
          <div className="link-wrapper">
            <li>
              <a href="#Banner"> Banner</a>
            </li>
          </div>
          <div className="link-wrapper">
            <li>
              <a href="#Products">Projects</a>
            </li>
          </div>
        </>
      );
    } else if (currentPath === "/aboutUs") {
      return (
        <>
          <div className="link-wrapper">
            <li>
              <a href="#Banner"> Banner</a>
            </li>
          </div>
          <div className="link-wrapper">
            <li>
              <a href="#Vision">Our Vision</a>
            </li>
          </div>
          <div className="link-wrapper">
            <li>
              <a href="#Team">Our Team</a>
            </li>
          </div>
        </>
      );
    } else if (currentPath === "/swap") {
      return (
        <>
          <div className="link-wrapper">
            <li>
              <a href="#Swap">Swap</a>
            </li>
          </div>
        </>
      );
    } else if (currentPath === "/policy") {
      return (
        <>
          <div className="link-wrapper">
            <li>
              <a href="#Banner">Banner</a>
            </li>
          </div>

          <div className="link-wrapper">
            <li>
              <a href="#Privacy-policy">Privacy policy</a>
            </li>
          </div>
        </>
      );
    } else if (currentPath === "/DAO") {
      return (
        <>
          <div className="link-wrapper">
            <li>
              <a href="#Banner">Banner</a>
            </li>
          </div>
        </>
      );
    }
  };

  return (
    <>
      <section id="Footer">
        <Container>
          <Row className="footer-wrapper d-flex justify-content-center">
            <Col
              xs="12"
              md="9"
              lg="4"
              className="d-flex flex-column justify-content-center align-items-center"
            >
                <a href="/">
                  <img className="stellargate-logo mt-2 " src={ISS} alt="logo" />
                </a>
                <p className="footer-text">
                Infinite Star Studios (ISS). Lima, Perú.
                </p>
                <p className="footer-text py-2">
                Contact us at: hello@stellargate.io
                </p>
                {/* 
                  <img
                    className="phantasma-logo mt-3"
                    src={phantasma}
                    alt="phantasma logo"
                  />
                  */}
            </Col>
            <Col xs="6" sm="4" lg="2" className="d-flex justify-content-center">
              <ul className="p-0">
                <div className="position-relative">
                  <div className="title-footer">Pages</div>
                </div>

                <div className="link-wrapper">
                  <li>
                    <a href="/">Home</a>
                  </li>
                </div>

                <div className="link-wrapper">
                  <li>
                    <a href="/AboutUs">About Us</a>
                  </li>
                </div>

                <div className="link-wrapper">
                  <li>
                    <a href="/policy">Privacy policy</a>
                  </li>
                </div>
              </ul>
            </Col>
            <Col xs="6" sm="4" lg="2" className="d-flex justify-content-center">
              <ul className="p-0">
                <div className="position-relative">
                  <div className="title-footer">Social media</div>
                </div>

                <div className="link-wrapper position-relative">
                  <li>
                    <a
                      target="blank"
                      href="https://www.linkedin.com/company/stellar-gate?trk=public_profile_topcard-current-company"
                    >
                      Linkedin
                    </a>
                  </li>
                </div>

                <div className="link-wrapper position-relative">
                  <li>
                    <a
                      target="blank"
                      href="https://mail.google.com/mail/u/0/#inbox?compose=CllgCJZZzpVlDtbqmbWCjxBsChtVFHsnwKRmpsLhQcHztLNMqjGmZZfdxcxLDMKVBqmcvMjnJNV"
                    >
                      E-mail
                    </a>
                  </li>
                </div>

                <div className="link-wrapper position-relative">
                  <li>
                    <a target="blank" href="https://discord.gg/vV6Evg2FCY">
                      Discord
                    </a>
                  </li>
                </div>

                <div className="link-wrapper position-relative">
                  <li>
                    <a
                      target="blank"
                      href="https://www.facebook.com/stellargatego"
                    >
                      Facebook
                    </a>
                  </li>
                </div>

                <div className="link-wrapper position-relative">
                  <li>
                    <a target="blank" href="https://t.me/stellargateio">
                      Telegram
                    </a>
                  </li>
                </div>

                <div className="link-wrapper position-relative">
                  <li>
                    <a
                      target="blank"
                      href="https://www.instagram.com/stellargate_io/ "
                    >
                      Instagram
                    </a>
                  </li>
                </div>

                <div className="link-wrapper position-relative">
                  <li>
                    <a target="blank" href="https://linktr.ee/stellar_gate">
                      Linktree
                    </a>
                  </li>
                </div>

                <div className="link-wrapper position-relative">
                  <li>
                    <a
                      target="blank"
                      href="https://chat.whatsapp.com/EPuENlYhOBZC3HYriBB8wG"
                    >
                      Whatsapp
                    </a>
                  </li>
                </div>

                <div className="link-wrapper position-relative">
                  <li>
                    <a target="blank" href="https://twitter.com/stellargate_io">
                      X
                    </a>
                  </li>
                </div>
              </ul>
            </Col>
            <Col
              xs="12"
              sm="4"
              lg="2"
              className="d-flex justify-content-center"
            >
              <ul className="p-0">
                <div className="position-relative">
                  <div className="title-footer">Sections</div>
                </div>

                {handleContentChange()}
              </ul>
            </Col>
          </Row>
        </Container>

        <div className="text-center p-4 policy-wrapper">
          <p className="m-0">© 2023 by stellargate.io All Rights Reserved</p>
        </div>
      </section>
    </>
  );
};

export default Footer;
