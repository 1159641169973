//React Router Dom
import { Route, Routes } from "react-router-dom";

//Pages
import Home from "./pages/home/Home";
import AboutUs from "./pages/AboutUs/AboutUs";
import NotFound from "./reusable/NotFound/NotFound";
import Swap from "./pages/swap/Swap";
import RoadMap from "./pages/RoadMap/Roadmap";
import Policy from "./pages/policy/Policy";
import Whitepaper from "./pages/Whitepaper/Whitepaper";
import Game from "./pages/Game/Game";
import Token from "./pages/Token/Token";

//Styles
import "./styles/index.scss";

function App() {
  return (
    <>
      <Routes>
        <Route path="/" element={<Home />}></Route>
        <Route path="/aboutUs" element={<AboutUs />}></Route>
        <Route path="*" element={<NotFound />}></Route>
        <Route path="/swap" element={<Swap />}></Route>
        <Route path="/roadmap" element={<RoadMap />}></Route>
        <Route path="/policy" element={<Policy />}></Route>
        <Route path="/Whitepaper" element={<Whitepaper />}></Route>
        <Route path="/Game/" element={<Game />}></Route>
        <Route path="/TokenLaunch" element={<Token />}></Route>
      </Routes>
    </>
  );
}

export default App;
