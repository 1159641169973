import React from "react";
import { Row, Col, Container } from "reactstrap";

//Assets
import PCS from "../../assets/logo/PCSLogo.png";
import BSC from "../../assets/logo/BCSLogo.png";

const Launch = () => {
  return (
    <>
      <div id="Launch">
        <Container>
          <Row>
            <Col lg="12" className="d-flex justify-content-center mt-5">
              <span className="launching-text">Available in:</span>
            </Col>
            <Col className="logos-container my-5">
              <a href="https://bscscan.com/token/0x914298d8Eed75214A7001e53D3Ed6b2D87cD8cD8">
                <img
                  data-aos="fade-right"
                  data-aos-duration="1800"
                  className="logo"
                  src={BSC}
                  alt="Binance logo"
                  />  
              </a>
              <a href="https://pancakeswap.finance/swap?outputCurrency=0x914298d8Eed75214A7001e53D3Ed6b2D87cD8cD8&inputCurrency=BNB">
                <img
                  data-aos="fade-left"
                  data-aos-duration="1800"
                  className="logo"
                  src={PCS}
                  alt="Pancake logo"
                  />
              </a>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Launch;
