import React from "react";
import { Row, Col } from "reactstrap";

//img
import Tank from "../../assets/projectImg/tank.jpg";
import Drone from "../../assets/projectImg/drone.jpg";
import citArt from "../../assets/projectImg/cit-art.png";

//card structure
import ProjectItems from "./projectItems/ProyectItems";

const Projects = () => {
  const projectItems = [
    {
      img: Tank,
      title: "AI assisted artillery call",
      subTitle: "Our latest progress using AI",
      content:
        "We have accomplished integration of AI into our game. We can now talk in real time with AI to coordinate for artillery support.",
      link: "https://www.youtube.com/embed/dgGlvB5fD_4?si=x7-OMa7FaG2R8nMT",
    },

    {
      img: citArt,
      title: "Solar Operations Center",
      subTitle: "Our dApp is now online.",
      content:
        "Our dApp is now online which allows for crosschain operations and usage of our DEX in phantasma chain.",
      buttonTitle: "Check it out",
      link: "https://soc.stellargate.io",
    },
  ];

  return (
    <div id="Projects">
      <Row className="gap-4">
        <Col xs="11" lg="8" xl="7" className="mx-auto">
          <div className="title-projects text-center m-0">
            <span className="gradient-word">Decentralized</span> group of
            artists and programmers
          </div>
        </Col>

        <Col xs="11" lg="6" className="d-flex justify-content-center mx-auto">
          <p className="text-center">
            We are a decentralized group of artists and programmers who have
            embarked on creating the ultimate futuristic sci-fi game integrated
            with blockchain technologies
          </p>
        </Col>

        <Col lg="7" className="d-flex justify-content-center mx-auto">
          <div className="gradient-button">
            <a href="/roadmap">Learn more</a>
          </div>
        </Col>
      </Row>

      <Row className="w-100 m-0 pt-5 d-flex justify-content-center">
        {projectItems ? (
          projectItems.map((el, idx) => {
            return (
              <Col
                xs="11"
                md="10"
                lg="8"
                xl="6"
                className="pt-5"
                key={idx}
                data-aos="fade-up"
                data-aos-duration={idx * 1000 + 2000}
              >
                <ProjectItems
                  img={el.img}
                  title={el.title}
                  subTitle={el.subTitle}
                  content={el.content}
                  buttonTitle={el.buttonTitle}
                  link={el.link}
                />
              </Col>
            );
          })
        ) : (
          <></>
        )}
      </Row>
    </div>
  );
};

export default Projects;
