import React from "react";
import { Row, Col } from "reactstrap";

import bitcoin from "../../assets/img/bitcoin.png";
import usdt from "../../assets/img/usdt.png";
import arrows from "../../assets/svg/arrows.svg";

const SwapBanner = () => {
  return (
    <div id="Swap">
      <Row className="gap-4">
        <Col
          lg="12"
          data-aos="fade-up"
          data-aos-duration="1200"
          className="swap-wrapper"
        >
          <div className="swap-card p-4">
            <p className="title-swap mb-1">Swap - NOT YET AVAILABLE</p>
            <p className="subtitle-swap">Trade tokens in an instant.</p>
            <div className="line pt-3"></div>

            <div className="rectangle">
              <input type="number" className="money" placeholder="0.00" />
              <div className="currency-container">
                <img src={bitcoin} alt="icon" />
                <span>BTC</span>
              </div>
            </div>

            <div className="arrows-container d-flex justify-content-center">
              <img src={arrows} alt="arrows" className="mt-4 mb-4" />
            </div>

            <div className="rectangle mt-0">
              <input type="number" className="money" placeholder="0.00" />
              <div className="currency-container">
                <img src={usdt} alt="icon" />
                <span>USDT</span>
              </div>
            </div>

            <div className="fee-container">
              <div className="fee-text">Fee</div>
              <span className="fee-number">+0.40 USD</span>
            </div>

            <div className="gray-line pt-2"></div>

            <div className="gradient-button">
              <a href="/">SWAP</a>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default SwapBanner;
