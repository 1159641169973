import React, { useEffect, useState } from "react";
import { Row, Col, Container } from "reactstrap";
//React Scroll Parallax
import { Parallax } from "react-scroll-parallax";

//Assets
import sky from "../../assets/token-banner/countdown.png";
import mountain from "../../assets/token-banner/mountain.png";
import Raa from "../../assets/logo/RAA-logo-w.png";
import stellarlogo from "../../assets/logo/stellarlogo.png";
import rectangle from "../../assets/token-banner/Rectangle.png";

const Banner = () => {
  const targetDate = new Date("2023-12-19T12:00:00");
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  function calculateTimeLeft() {
    const now = new Date();
    const difference = targetDate - now;
    return difference > 0 ? difference : 0;
  }

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  const formatTime = (time) => {
    const days = Math.floor(time / (1000 * 60 * 60 * 24));
    const hours = Math.floor((time / (1000 * 60 * 60)) % 24);
    const minutes = Math.floor((time / (1000 * 60)) % 60);
    const seconds = Math.floor((time / 1000) % 60);

    const formattedDays = String(days).padStart(2, "0");
    const formattedHours = String(hours).padStart(2, "0");
    const formattedMinutes = String(minutes).padStart(2, "0");
    const formattedSeconds = String(seconds).padStart(2, "0");

    return `${formattedDays} : ${formattedHours} : ${formattedMinutes} : ${formattedSeconds}`;
  };

  return (
    <>
      <img className="sky" src={sky} alt="sky" />
      <div className="Banner">
        <Parallax speed={-15} className="Parallax">
          <img className="mountain" src={mountain} alt="mountain" />
        </Parallax>
        <Container className="h-100">
          <Row className="d-flex flex-column align-items-center h-100">
            <Col className="d-flex flex-column align-items-center justify-content-center h-100">
              <div className="d-flex justify-content-center">
                <div className="logo-container">
                  <img
                    className="stellar-logo mt-5"
                    src={Raa}
                    alt="Raa Logo"
                  />
                </div>
                <div className="content">
                  <div className="text-center token-title mb-3 mt-5">
                    RAA TOKEN <span className="launch"> LIVE</span>
                  </div>
                  <a href="https://bscscan.com/token/0x914298d8Eed75214A7001e53D3Ed6b2D87cD8cD8" className="text-decoration-none">
                    <div className="time-container">
                      <div>

                        <img
                          className="rectangle"
                          src={rectangle}
                          alt="rectangle"
                          />
                        <h1 className="text-center time-raa pt-3 px-3 text-decoration-none">
                          <h2>Contract:</h2>
                          0x914298d8Eed75214A7001e53D3Ed6b2D87cD8cD8
                        </h1>
                      </div>
                  </div>
                  </a>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Banner;
