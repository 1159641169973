import React from "react";
import { Row, Col, Container } from "reactstrap";

/* //social media
import facebook from "../../assets/social-media/facebookimg.png";
import youtube from "../../assets/social-media/youtubeimg.png" */ const Vision =
  () => {
    return (
      <div id="Vision">
        <Container className="h-100">
          <Row className="gap-4">
            <Col lg="12" data-aos="fade-up" data-aos-duration="1200">
              <h1 className="title-vision text-center m-0">
                OUR <span className="vision-gradient">VISION</span> WHAT WE{" "}
                <span className="vision-gradient">DO</span>?
              </h1>
            </Col>

            <Col
              xs="11"
              lg="8"
              className="d-flex justify-content-center mx-auto"
            >
              <p
                className="text-center"
                data-aos="fade-up"
                data-aos-duration="1500"
              >
                We look forward to providing gamers and non-gamers with the
                opportunity to create a better world and encourage members to
                unify under a solar flag. We aim to deliver the best game
                experience and projects to keep the Earth and our planets in the
                Solar System safe!
              </p>
            </Col>

            <Col
              xs="11"
              lg="8"
              className="d-flex justify-content-center mx-auto"
            >
              <p
                className="text-center"
                data-aos="fade-up"
                data-aos-duration="1800"
              >
                We are a decentralized group willing to achieve a full-fledged
                game that will connect the virtual world with the real world.
                Whether it be supporting space combat or education on Earth, we
                are looking to provide the best experience to all citizens of
                our amazing Solar System!
              </p>
            </Col>

            {/* <Col lg="7" className="d-flex justify-content-center mx-auto">
            <div className="social-media-container">
              <div className="img-wrapper"
                  data-aos="fade-right" data-aos-duration="2000">
                <a href="aboutUs">
                  <img src={facebook} alt="facebook" />
                </a>
              </div>

              <div className="img-wrapper"
                  data-aos="fade-left" data-aos-duration="2000">
                <a href="/aboutUs">
                  <img src={youtube} alt="youtube" />
                </a>
              </div>
            </div>
          </Col> */}
          </Row>
        </Container>
      </div>
    );
  };

export default Vision;
