import {React, useState, useEffect} from "react";
import { Row, Col, Container } from "reactstrap";
//React Scroll Parallax
import { Parallax } from "react-scroll-parallax";

//Assets

import banner from "../../assets/token-banner/banner.jpg"
import CITlogo from "../../assets/token-banner/CIT-logo-abs.png"
import CITlogoW from "../../assets/token-banner/CIT-logo-abs-w.png"


import rectangle from "../../assets/token-banner/Rectangle.png";

const CITBanner = () => {
  const targetDate = new Date("2023-12-19T12:00:00");
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  function calculateTimeLeft() {
    const now = new Date();
    const difference = targetDate - now;
    return difference > 0 ? difference : 0;
  }

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  const formatTime = (time) => {
    const days = Math.floor(time / (1000 * 60 * 60 * 24));
    const hours = Math.floor((time / (1000 * 60 * 60)) % 24);
    const minutes = Math.floor((time / (1000 * 60)) % 60);
    const seconds = Math.floor((time / 1000) % 60);

    const formattedDays = String(days).padStart(2, "0");
    const formattedHours = String(hours).padStart(2, "0");
    const formattedMinutes = String(minutes).padStart(2, "0");
    const formattedSeconds = String(seconds).padStart(2, "0");

    return `${formattedDays} : ${formattedHours} : ${formattedMinutes} : ${formattedSeconds}`;
  };

  return (
    <div className="CITBanner">
      <img className="CITsky" src={banner} alt="sky" />
      <div className="Banner-C">
        <Parallax speed={-15} className="Parallax">
          <img className="mountain" src={banner} alt="mountain" />
        </Parallax>
        <Container>
          <Row>
            <Col>
              <div className="d-flex justify-content-center mt-5 pt-5">
                <div className="logo-container-cit mt-4">
                  <img
                    className="stellar-logo"
                    src={CITlogoW}
                    alt="CIT token logo"
                  />
                </div>
                <div className="content-cit text-center">
                  <div className="text-center token-title mb-3 mt-5">
                  CIT TOKEN <span className="launch">LIVE</span>
                  </div>
                  <a href="https://bscscan.com/token/0x9C285F7e70c12173c8dB335Be1A56F2231fD84E2" className="text-decoration-none">
                    <div className="text-center time-container">
                      <div>

                        <img
                          className="rectangle"
                          src={rectangle}
                          alt="rectangle"
                          />
                        <h1 className="text-center time-cit pt-3 px-3 text-decoration-none">
                          <h2>Contract:</h2>
                          0x9C285F7e70c12173c8dB335Be1A56F2231fD84E2
                        </h1>
                      </div>
                  </div>
                  </a>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default CITBanner;
