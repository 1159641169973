import React from "react";
import { Row, Col } from "reactstrap";

//Social Media
import discord from "../../assets/social-media/logos_discord-icon.png";
import twitter from "../../assets/social-media/X.png";
import facebook from "../../assets/social-media/brandico_facebook-rect.png";
import telegram from "../../assets/social-media/logos_telegram.png";
import instagram from "../../assets/social-media/instagram.png";
import linktree from "../../assets/social-media/linktree.png";
import whatsapp from "../../assets/social-media/whatsapp.png";

const JoinUs = () => {
  return (
    <div id="JoinUs">
      <Row className="gap-1">
        <Col xs="11" lg="9" className="mx-auto">
          <div className="title-JoinUs text-center m-0">
            JOIN OUR <span className="gradient-word">COMMUNITY</span> TO STAY
            UPDATED
          </div>
        </Col>

        <Col xs="11" lg="9" className="d-flex justify-content-center mx-auto">
          <p className="text-center">
            Join us in discord for more:{" "}
            <a
              target="blank"
              className="discord-link"
              href="https://discord.gg/vV6Evg2FCY"
            >
              https://discord.gg/vV6Evg2FCY
            </a>
          </p>
        </Col>

        <Col
          xs="12"
          md="9"
          className="d-flex justify-content-center mx-auto"
          data-aos-duration="2000"
          data-aos="zoom-in-up"
        >
          <div className="social-media-container">
            <div className="img-wrapper">
              <a
                target="blank"
                href="https://www.instagram.com/stellargate_io/"
              >
                <img src={instagram} alt="instagram" className="social-media" />
              </a>
              <a
                target="blank"
                href="https://www.instagram.com/stellargate_io/"
              >
                <div className="description">INSTAGRAM</div>
              </a>
            </div>
            <div className="img-wrapper">
              <a target="blank" href="https://www.facebook.com/stellargatego">
                <img src={facebook} alt="facebook" className="social-media" />
              </a>
              <a target="blank" href="https://www.facebook.com/stellargatego">
                <div className="description">FACEBOOK</div>
              </a>
            </div>
            <div className="img-wrapper">
              <a target="blank" href="https://twitter.com/stellargate_io">
                <img src={twitter} alt="twitter" className="social-media" />
              </a>
              <a target="blank" href="https://twitter.com/stellargate_io">
                <div className="description">X</div>
              </a>
            </div>
            <div className="img-wrapper">
              <a target="blank" href="https://linktr.ee/stellar_gate">
                <img src={linktree} alt="linktree" className="social-media" />
              </a>
              <a target="blank" href="https://linktr.ee/stellar_gate">
                <div className="pt-0 description">LINKTREE</div>
              </a>
            </div>
            <div className="img-wrapper">
              <a target="blank" href="https://discord.gg/vV6Evg2FCY">
                <img src={discord} alt="discord" className="social-media" />
              </a>
              <a target="blank" href="https://discord.gg/vV6Evg2FCY">
                <div className="description">DISCORD</div>
              </a>
            </div>

            <div className="img-wrapper">
              <a target="blank" href="https://t.me/stellargateio">
                <img src={telegram} alt="telegram" className="social-media" />
              </a>
              <a target="blank" href="https://t.me/stellargateio">
                <div className="description">TELEGRAM</div>
              </a>
            </div>
            <div className="img-wrapper">
              <a
                target="blank"
                href="https://chat.whatsapp.com/EPuENlYhOBZC3HYriBB8wG"
              >
                <img src={whatsapp} alt="whatsapp" className="social-media" />
              </a>
              <a
                target="blank"
                href="https://chat.whatsapp.com/EPuENlYhOBZC3HYriBB8wG"
              >
                <div className="description">whatsapp</div>
              </a>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default JoinUs;
