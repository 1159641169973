import React from "react";
import { Row, Col } from "reactstrap";
import ProductItems from "./productItems/ProductItems";

//Cicles
import circle1 from "../../assets/roadmap-shapes/circles/circle-1.svg";
import circle2 from "../../assets/roadmap-shapes/circles/circle-2.png";
import circle3 from "../../assets/roadmap-shapes/circles/circle-3.png";
import circle4 from "../../assets/roadmap-shapes/circles/circle-4.png";
import circle5 from "../../assets/roadmap-shapes/circles/circle-5.png";
import circle6 from "../../assets/roadmap-shapes/circles/circle-6.png";
import circle7 from "../../assets/roadmap-shapes/circles/circle-7.png";
import circle8 from "../../assets/roadmap-shapes/circles/circle-8.png";

//Shapes

import shape1 from "../../assets/roadmap-shapes/shapes/phase1.png";
import shape2 from "../../assets/roadmap-shapes/shapes/phase2.png";
import shape3 from "../../assets/roadmap-shapes/shapes/phase3.png";
import shape4 from "../../assets/roadmap-shapes/shapes/phase4.png";
import shape5 from "../../assets/roadmap-shapes/shapes/phase5.png";
import shape6 from "../../assets/roadmap-shapes/shapes/phase6.png";
import shape7 from "../../assets/roadmap-shapes/shapes/phase7.png";
import shape8 from "../../assets/roadmap-shapes/shapes/phase8.png";

//Bars

import bar1 from "../../assets/roadmap-shapes/bars/phase1-bar.png";
import bar2 from "../../assets/roadmap-shapes/bars/phase2-bar.png";
import bar3 from "../../assets/roadmap-shapes/bars/phase3-bar.png";
import bar4 from "../../assets/roadmap-shapes/bars/phase4-bar.png";
import bar5 from "../../assets/roadmap-shapes/bars/phase5-bar.png";
import bar6 from "../../assets/roadmap-shapes/bars/phase6-bar.png";
import bar7 from "../../assets/roadmap-shapes/bars/phase7-bar.png";
import bar8 from "../../assets/roadmap-shapes/bars/phase8-bar.png";

const Products = () => {
  const productItems = [
    {
      circle: circle1,
      shape: shape1,
      title: `PHASE I`,
      CurrentState: `Done`,
      bar: bar1,
      subtitle1: `• Promethei Terra Exploration Module v1-1 Months`,
      content1a: `• Allow citizens to explore on website the briefing room, manufacturing room, mineral processing room and medbay.`,
      subtitle2: `• Revamp Bot to Version 3 1-2 Months`,
      content2a: `• Allow to provide service to other communities within Phantasma.`,
      content2b: `• Ability to assign fake tokens to citizens to track in database amount of tokens needed to distribute to wallets upon launch.`,
      content2c: `• Allow for other communities to track tokens of their choice within Phantasma chain.`,
    },

    {
      circle: circle2,
      shape: shape2,
      title: `PHASE II`,
      CurrentState: ``,
      bar: bar2,
      subtitle1: `• Hangar Module v1 1-3 Months`,
      content1a: `• Allow for players to board shuttle for mars development.`,
      content1b: `• Citizens who own exclusive shuttle NFT will have ability to spawn it instead of standard.`,
      subtitle2: `• Revamp Bot to Version 4 1-4 Months`,
      content2a: `• Allow for community owners to track price of tokens of their choice in real life.`,
      content2b: `• Allow for community owners to track price of NFT collection in real time.`,
      content2c: `• Infrastructure update.`,
    },

    {
      circle: circle3,
      shape: shape3,
      title: `PHASE III`,
      CurrentState: ``,
      bar: bar3,
      subtitle1: `• Mining Module v1 3-4 Months`,
      content1a: `• Allow for citizens to be able to use shuttle to be transported to mars.`,
      content1b: `• Allow for exploration of mars and abandoned mining facility.`,
      subtitle2: `• Promethei Terra v2 4-5 Months`,
      content2a: `• Allow for communities to expose their projects in Stellar Gate Metaverse.`,
      content2b: `• Provide gated access for communities to host events.`,
      content2c: `• Provide marketing support for communities to expose their projects within booths in Stellar Gate.`,
      content2d: `• Allow artists to hold music events.`,
    },

    {
      circle: circle4,
      shape: shape4,
      title: `PHASE IV`,
      CurrentState: ``,
      bar: bar4,
      subtitle1: `• RAA and TAZZ Token Launch 3-4 Months`,
      content1a: `• Kick off the launch of RAA and TAZZ tokens on Phantasma chain.`,
      content1b: `• Enable website version 3 to allow for connection of wallet and presale purchase of tokens.`,
      subtitle2: `• Mining Module v2 2-3 Months`,
      content2a: `• Allow citizens to mine minerals.`,
      content2b: `• Expand exploration of tunnels.`,
      content2c: `• Deploy enemies. Update Ul.`,
      subtitle3: `• Mining Processing Module V1 2-3 Months`,
      content3a: `• Allow promethei terra to provide mineral processing services.`,
      content3b: `• Rework mineral processing services area to a better suited area in Promethei Terra`,
    },

    {
      circle: circle5,
      shape: shape5,
      title: `PHASE V`,
      CurrentState: ``,
      bar: bar5,
      subtitle1: `• Manufacturing Module vi`,
      content1a: `• Allow citizens to explore manufacturing area.`,
      content1b: `• Allow to citizens to manufacture components and items on minerals processed.`,
      subtitle2: `• Mineral Processing v2`,
      content2a: `• Upgrade mineral processing mechanism.`,
      content2b: `• Check stats and update accordingly.`,
      content2c: `• Allow for rewards to be claimed.`,
    },

    {
      circle: circle6,
      shape: shape6,
      title: `PHASE VI`,
      CurrentState: ``,
      bar: bar6,
      subtitle1: `• Mining Module vi `,
      content1a: `• Allow citizens to sell ores to the Solar Planetary Alliance (SPA) in exchange for RAA.`,
      content1b: `• Creation of special events to promote mining fun.`,
      subtitle2: `• Mineral Processing v3`,
      content2a: `• Expand ore processes`,
      content2b: `• Upgrade UI and allow for better sorting of ore processes.`,
      content2c: `• Allow for access into new mining module area aboard promethel terra.`,
      subtitle3: `• Mineral Processing v2`,
      content3a: `• Expand components for making.`,
      content3b: `• Expand items for citizen to create.`,
    },

    {
      circle: circle7,
      shape: shape7,
      title: `PHASE VII`,
      CurrentState: ``,
      bar: bar7,
      subtitle1: `• Merchant Office vI`,
      content1a: `• Allow NFT token holders to deploy the first Merchant Offices in game.`,
      content1b: `• Allow for loans to be created by Merchant Offices.`,
      subtitle2: `• Nomenclature v1`,
      content2a: `• Classified`,
    },

    {
      circle: circle8,
      shape: shape8,
      title: `PHASE VIII`,
      CurrentState: ``,
      bar: bar8,
      subtitle1: `• Customization v1`,
      content1a: `• Classified`,
      subtitle2: `• Promethei Terra City Expansion`,
      content2a: `• Allow for citizens to participate in purchase of city areas within promethei terra.`,
      content2b: `• Allow for citizens to trade areas.`,
      content2c: `• Allow for 3D exploration of city.`,
      content2d: `• Establishment of first city council and planning services.`,
    },
  ];

  return (
    <div id="Products">
      <Row className="w-100 m-0 pt-5 d-flex justify-content-center">
        {productItems ? (
          productItems.map((el, idx) => {
            return (
              <Col
                xs="12"
                md="6"
                lg="4"
                xl="3"
                className="products-container d-flex justify-content-center"
                key={idx}
              >
                <ProductItems
                  circle={el.circle}
                  shape={el.shape}
                  title={el.title}
                  CurrentState={el.CurrentState}
                  bar={el.bar}
                  subtitle1={el.subtitle1}
                  content1a={el.content1a}
                  content1b={el.content1b}
                  subtitle2={el.subtitle2}
                  content2a={el.content2a}
                  content2b={el.content2b}
                  content2c={el.content2c}
                  content2d={el.content2d}
                  val={idx}
                />
              </Col>
            );
          })
        ) : (
          <></>
        )}
      </Row>
    </div>
  );
};

export default Products;
