import React, { useContext } from "react";
import { MainContext } from "../../context/MainContext";
import Layout from "../../reusable/layout/Layout";

//Components
import Banner from "../../components/token/Banner";
import Launch from "../../components/token/Launch";
import CITBanner from "../../components/token/CITBanner";
import CITLaunch from "../../components/token/CITLaunch";
import Raainfo from "../../components/token/RaaInfo";
import CITinfo from "../../components/token/CITinfo";

const Token = () => {
  const context = useContext(MainContext);
  if (!context) {
    console.log("useContext must be used within a TaskContextProvider");
  }

  return (
    <Layout>
      <section id="Token">
        <Banner />
        <Raainfo />
        <Launch />
        <CITBanner />
        <CITinfo />
        <CITLaunch />
        <h6 className="text-center text-light my-5">Everything mentioned is not final. Things may change as we progress in development</h6>
      </section>
    </Layout>
  );
};

export default Token;
