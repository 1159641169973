import React from "react";
import { Row, Col, Container } from "reactstrap";

//Assets
import ChartRaa from "../../assets/img/Raachart.png";

const Raainfo = () => {
  return (
    <>
      <div id="Launch">
        <Container>
          <Row className="">
            <Col lg="12" className="d-flex justify-content-center mt-5 text-light w-100">
                <img src={ChartRaa} height="100%" width="100%" alt="RAA Char" srcset="" />
            </Col>
            <Col lg="5" className="d-flex justify-content-center mt-5 text-light initial-allocation">
                <div>
                    <span className="launching-text pb-5">RAA  Initial Allocation</span>
                    <div className="d-flex flex-column justify-content-between h-75">
                        <p>
                            - Max supply 10,000 RAA
                        </p>
                        <p>
                            - Initial circulating supply 1,000 RAA
                        </p>
                        <p className="d-flex">
                            - <div className="percentage-space">1%</div> Provided to miners  (mining players)
                        </p>
                        <p className="d-flex">
                            - <div className="percentage-space">1%</div> Community 
                        </p>
                        <p className="d-flex">
                            - <div className="percentage-space">2%</div> Staff 
                        </p>
                        <p className="d-flex">
                            - <div className="percentage-space">4% </div>SPA  (future DAO)
                        </p>
                        <p className="d-flex">
                            - <div className="percentage-space">20%</div> CEX 
                        </p>
                        <p className="d-flex">
                            - <div className="percentage-space">36%</div> DEX 
                        </p>
                        <p className="d-flex">
                            - <div className="percentage-space">36%</div> Interplanetary Central Reserve (ICR) 
                        </p>
                    </div>
                </div>
            </Col>
            <Col lg="1">
            </Col>
            <Col lg="5" className="d-flex justify-content-end mt-5 text-light">
                <div className=" raa-chart-tax">
                    <span className="launching-text pb-5">
                       Tax Allocation
                    </span>
                    <div className="d-flex flex-column justify-content-between h-75 pt-1">
                        <p>
                            - Tax at launch 2%
                        </p>
                        <p>
                            - Tax hardcoded from 0% - 7% maximum
                        </p>
                        <p className="launching-sub-text pb-3 pt-3">
                            The following will be funded by the tax system
                        </p>
                        <p className="d-flex">
                            - <div className="percentage-space">4%</div> to Merchant office
                        </p>
                        <p className="d-flex">
                            - <div className="percentage-space">7%</div> to DAO SPA
                        </p>
                        <p className="d-flex">
                            - <div className="percentage-space">18%</div> to best Players and events
                        </p>
                        <p className="d-flex"> 
                            - <div className="percentage-space">20%</div> to developer
                        </p>
                        <p className="d-flex">  
                            - <div className="percentage-space">51%</div> to interplanetary central reserve (ICR)
                        </p>
                    </div>
                </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Raainfo;
