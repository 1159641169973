import React from "react";
import { Row, Col, Container } from "reactstrap";

const WhatIs = () => {
  return (
    <div id="WhatIs">
      <Container className="h-100">
        <Row className="gap-4">
          <Col lg="12" data-aos="fade-up" data-aos-duration="1200">
            <h1 className="title-WhatIs text-center m-0">
              WHAT IS <span className="WhatIs-gradient">STELLAR GATE</span> ?
            </h1>
          </Col>

          <Col xs="11" lg="8" className="d-flex justify-content-center mx-auto">
            <p
              className="text-center"
              data-aos="fade-up"
              data-aos-duration="1500"
            >
              <p className="content">
                Stellar Gate is a first person shooter (FPS) game crossover with
                real time strategy (RTS) elements. We categorize our game within
                the play for fun spectrum powered by blockchain. Our expansive
                knowledge of games has allowed us to bring what every gamer
                desires, the ability to create your own experience.
              </p>
            </p>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default WhatIs;
