import React from "react";
import { Row, Col } from "reactstrap";

//img
import experience from "../../assets/experience/BlockchainExperience.png";

const Experience = () => {
  return (
    <div id="Experience">
      <Row className="d-flex justify-content-center">
        <Col
          xs="11"
          md="10"
          lg="6"
          className="d-flex justify-content-center align-items-center"
          data-aos="fade-right"
          data-aos-duration="2500"
        >
          <div className="img-container">
            <img className="blockchain-img w-100" src={experience} alt="" />
          </div>
        </Col>
        <Col
          xs="11"
          md="10"
          lg="6"
          className="mx-auto"
          data-aos="fade-left"
          data-aos-duration="2500"
        >
          <div className="title-experience m-0">
            <span className="gradient-word">Blockchain</span> Powered Experience
          </div>

          <p>
            Welcome to the enthralling universe of Stellar Gate, where the power
            to forge your own destiny lies in your hands! Embrace the thrill of
            mining, engage in fierce combat, acquire rare items, or dominate
            space battles – the choice is yours! And rest assured, with
            blockchain technology, your hard-earned possessions are genuinely
            and securely yours!
          </p>

          <div className="blockchain-button mt-4 pb-3">
            <a aria-disabled="true" href="/">
              More coming soon
            </a>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Experience;
