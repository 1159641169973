import React from "react";
import { Row, Col, Container } from "reactstrap";

//Assets
import ChartCIT from "../../assets/img/CITchart.png";

const CITinfo = () => {

    const CirculatingSupply = [
        { from: 'Binance', to: 'Phantasma' },
        { from: 'Phantasma', to: 'Binance' },
        { from: 'Phantasma', to: 'Ethereum' },
        { from: 'Ethereum', to: 'Phantasma' },
        { from: 'Binance', to: 'Ethereum' },
        { from: 'Ethereum', to: 'Binance' },
      ]

    const CirculatingSupplyPartTwo = [
        { from: 'Binance', to: 'SKALE' },
        { from: 'SKALE', to: 'Binance' },
        { from: 'Binance', to: 'Tezos' },
        { from: 'Tezos', to: 'Binance' },
        { from: 'Binance', to: 'Thorfi' },
        { from: 'Thorfi', to: 'Binance' }
    ]
    

  return (
    <>
      <div id="Launch">
        <Container>
          <Row className=" justify-content-center">
          <Col lg="3" className="d-flex flex-column justify-content-center align-items-center mt-5 text-light">
                <div className=" raa-chart-tax mt-5 align-items-center">
                    <span className="launching-text pb-5">
                        Token allocation
                    </span>
                    <div className="d-flex flex-column justify-content-between 50">
                        <p className="d-flex">
                            - <div className="percentage-space">10%</div> Team
                        </p>                        
                        <p className="d-flex">
                            - <div className="percentage-space">10%</div> KCAL pair
                        </p>
                        <p className="d-flex">
                            - <div className="percentage-space">10%</div> SOUL pair
                        </p>
                        <p className="d-flex"> 
                            - <div className="percentage-space">20%</div> RAA pair
                        </p>
                        <p className="d-flex">
                            - <div className="percentage-space">50%</div> Burn
                        </p>
                    </div>
                </div>
            </Col>
            <Col lg="8" className="d-flex justify-content-center mt-5 text-light">
                <img src={ChartCIT} height="100%" width="100%" alt="CIT chart" srcset="" />
            </Col>
            <Col lg="12" className="d-flex justify-content-center mt-5 text-light text-center initial-allocation">
                <div>
                    <span className="launching-text text-center pb-5">Planned routes</span>
                    <div className="d-flex flex-wrap gap-5 h-75 justify-content-center">
                        <div className="d-flex flex-column justify-content-center">
                            {
                                CirculatingSupply.map((routes)=>(
                                    <p className="d-flex">
                                        - From <div className="From-space">{routes.from}</div>to  {routes.to}
                                    </p>
                                ))
                            }
                        </div>
                        <div className="d-flex flex-column justify-content-between">
                            {
                                CirculatingSupplyPartTwo.map((routes)=>(
                                    <p className="d-flex">
                                        - From <div className="From-space">{routes.from}</div>to  {routes.to}
                                    </p>
                                ))
                            }
                        </div>
                    </div>
                </div>
            </Col>
            <Col lg="1">
            </Col>
            
          </Row>
        </Container>
      </div>
    </>
  );
};

export default CITinfo;
