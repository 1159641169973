import React from "react";
import Layout from "../../reusable/layout/Layout";

//components
import Banner from "../../components/aboutUs/Banner";
import Vision from "../../components/aboutUs/Vision";
import AssistantRoles from "../../components/aboutUs/AssistantRoles";
import WhatIs from "../../components/aboutUs/WhatIs";
import Team from "../../components/aboutUs/Team";

const AboutUs = () => {
  return (
    <Layout>
      <section id="AboutUs">
        <Banner />
        <WhatIs />
        <Vision />
        <Team />
        <AssistantRoles />
      </section>
    </Layout>
  );
};

export default AboutUs;
