import React from "react";
import { Row, Col } from "reactstrap";

const Privacy = () => {
  return (
    <div id="Privacy-policy">
      <Row className="gap-4">
        <Col lg="12" data-aos="fade-up" data-aos-duration="1200">
          <h1 className="title-privacy">
            WE MAY <span className="gradient-word">COLLECT</span> THE FOLLOWING
            INFORMATION FROM YOU:
          </h1>
          <p>• Name, email address, and phone number.</p>
          <p>• Financial information such as credit/debit card details.</p>
          <p>• Other information relevant to customer surveys and/or offers.</p>
        </Col>

        <Col lg="12" data-aos="fade-up" data-aos-duration="1200">
          <h1 className="title-privacy">
            HOW WE USE THIS <span className="gradient-word">INFORMATION:</span>
          </h1>
          <p>
            We use your information to provide you with our services and to
            improve your experience on our website. Specifically, we may use
            your information for the following purposes:
          </p>
          <p>• To process transactions and complete orders.</p>
          <p>• To communicate with you regarding our services.</p>
          <p>• To provide customer support.</p>
          <p>• To improve our products and services.</p>
        </Col>

        <Col lg="12" data-aos="fade-up" data-aos-duration="1200">
          <h1 className="title-privacy">
            HOW WE PROTECT YOUR{" "}
            <span className="gradient-word">INFORMATION:</span>
          </h1>
          <p>
            We use your information to provide you with our services and to
            improve your experience on our website. Specifically, we may use
            your information for the following purposes:
          </p>
        </Col>

        <Col lg="12" data-aos="fade-up" data-aos-duration="1200">
          <h1 className="title-privacy">
            <span className="gradient-word">DISCLOSURE</span> OF INFORMATION:
          </h1>
          <p>
            We will not sell, rent, or trade your personal information to third
            parties. However, we may disclose your personal information if we
            are required by law to do so or if you violate our terms and
            conditions.
          </p>
        </Col>

        <Col lg="12" data-aos="fade-up" data-aos-duration="1200">
          <h1 className="title-privacy">
            <span className="gradient-word">COOKIES:</span>
          </h1>
          <p>
            We may use cookies to improve your experience on our website.
            Cookies are small data files that are placed on your device when you
            visit our website. You can disable cookies through your browser
            settings, but this may limit your ability to use some features of
            our website.
          </p>
        </Col>

        <Col lg="12" data-aos="fade-up" data-aos-duration="1200">
          <h1 className="title-privacy">
            LINKS TO OTHER <span className="gradient-word">WEBSITES:</span>
          </h1>
          <p>
            Our website may contain links to other websites that are not under
            our control. We are not responsible for the privacy practices or the
            content of such websites.
          </p>
        </Col>

        <Col lg="12" data-aos="fade-up" data-aos-duration="1200">
          <h1 className="title-privacy">
            CHANGES TO THIS{" "}
            <span className="gradient-word">PRIVACY POLICY:</span>
          </h1>
          <p>
            We reserve the right to change this privacy policy at any time. Any
            changes will be posted on this page, so please check back regularly
            for updates.
          </p>
        </Col>
      </Row>
    </div>
  );
};

export default Privacy;
