import React from "react";
import { Row, Col } from "reactstrap";

const Banner = () => {
  return (
    <div id="Banner">
      <Row className="gap-4">
        <Col lg="12" data-aos="fade-up" data-aos-duration="1200">
          <h1 className="title-banner text-center">
            <span className="new">PRIVACY</span> POLICY
          </h1>
        </Col>

        <Col xs="11" lg="7" className="d-flex justify-content-center mx-auto">
          <p
            className="text-center text-banner"
            data-aos="fade-up"
            data-aos-duration="1500"
          >
            At Stellar Gate, we take your privacy very seriously. This privacy
            policy sets out how we collect, use, and protect any personal
            information that you give us when you use our website to invest in
            games and coins.
          </p>
        </Col>

        <Col
          lg="7"
          className="d-flex justify-content-center mx-auto"
          data-aos="fade-up"
          data-aos-duration="2000"
        >
          <div className="gradient-button">
            <a href="#Privacy-policy">Explore</a>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Banner;
