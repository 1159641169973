import React from "react";
import { Row, Col, Container } from "reactstrap";
import whitepaper from "../../assets/pdf/StellarGateWhitePaperV3.pdf";

const Banner = () => {
  return (
    <div id="Banner">
      <Container className="h-100">
        <Row className="gap-4">
          <Col lg="12" data-aos="fade-up" data-aos-duration="1200">
            <h1 className="title-banner text-center m-0">
              STELLAR <span className="dao">Paper</span>
            </h1>
          </Col>

          <embed
            src={whitepaper}
            type="application/pdf"
            width="100%"
            height="1000px"
          />
        </Row>
      </Container>
    </div>
  );
};

export default Banner;
