import React from "react";
import Layout from "../../reusable/layout/Layout";

//components
import Banner from "../../components/Whitepaper/Banner";

const Whitepaper = () => {
  return (
    <Layout>
      <section id="Whitepaper">
        <Banner />
      </section>
    </Layout>
  );
};

export default Whitepaper;
