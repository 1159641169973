import React from "react";
import { Row, Col, Container } from "reactstrap";
import TeamCards from "./teamCards/TeamCards";

//Team photos
import Jorge from "../../assets/TeamPhotos/Jorge.png";
import Lena from "../../assets/TeamPhotos/Lena.jpg";
import Jesus1 from "../../assets/TeamPhotos/Jesus1.png";
import Mateo from "../../assets/TeamPhotos/Mateo.png";
import Frank from "../../assets/TeamPhotos/Frank.png";
import Mish from "../../assets/TeamPhotos/Mish.jpeg";
import Jesus2 from "../../assets/TeamPhotos/Jesus2.jpeg";
import Donna from "../../assets/TeamPhotos/Donna.jpeg";
import Mohammad from "../../assets/TeamPhotos/Mohammad.jpeg";
import AdinduDick from "../../assets/TeamPhotos/Adindu-Dick.jpeg";
import Enzo from "../../assets/TeamPhotos/Enzo.jpeg";

const Team = () => {
  let contador = 0;

  const TeamContent = [
    {
      Photo: Jorge,
      Name: "Jorge Bartra",
      Job: "Chief Executive Officer | Lead Developer",
      Linkedin: "https://www.linkedin.com/in/jbartra/",
    }, {
      Photo: Lena,
      Name: "Lena Eisenhardt",
      Job: "Lead AI assistant | Created by Jorge Bartra",
      Linkedin: "https://www.linkedin.com/in/jbartra/",
    },
    
  ];

  const ComunityVolunteers = [
    {
      Photo: Mateo,
      Name: "Mateo",
      Job: "Community Growth Manager",
      Linkedin: "https://www.linkedin.com/in/mateoo-quintana-50a073285/",
    }, {
        Photo: Mish,
        Name: "Lysa Cantoria",
        Job: "Lead Community Manager",
        Linkedin: "https://www.linkedin.com/in/cantoria/",
      },
    // {
    //   Photo: Jesus1,
    //   Name: "Jesus F. Marcano",
    //   Job: "Lead Blockchain/Web Developer",
    //   Linkedin: "https://www.linkedin.com/in/jes%C3%BAs-marcano-117b7024a/",
    // },
    {
      Photo: Frank,
      Name: "Mario Reyes",
      Job: "Growth specialist",
      Linkedin: "https://www.linkedin.com/in/mario-r-gonz%C3%A1lez-598518282",
    },
    {
      Photo: Donna,
      Name: "Donna Sanchez",
      Job: "Sr Moderator",
      Linkedin: "https://www.linkedin.com/in/donna-sanchez-582b79288",
    },
    {
      Photo: Mohammad,
      Name: "Mohammad Sarfaraz",
      Job: "Sr Moderator",
      Linkedin: "https://www.linkedin.com/in/mohammad-sarfaraz-afzal",
    },
    {
      Photo: AdinduDick,
      Name: "Adindu-Dick Chinazam",
      Job: "Sr Moderator",
      Linkedin: "https://ng.linkedin.com/in/naza-nelo-a91698228",
    },
    {
      Photo: Enzo,
      Name: "Enzo Oduca",
      Job: "Sr Moderator",
      Linkedin: "https://www.linkedin.com/in/kirito-kayaba-311b79288",
    },
  ];

  return (
    <div id="Team">
      <Container>
        <Col>
          <h1 className="title-team m-0">
            MEET OUR <span className="team-gradient">TEAM MEMBERS</span>
          </h1>
        </Col>

        <Row className="d-flex justify-content-center py-5">
          {TeamContent ? (
            TeamContent.map((el, idx) => {
              const val = contador;
              contador++;
              return (
                <Col
                  xs="9"
                  md="7"
                  lg="5"
                  xl="3"
                  className="mx-3 my-5"
                  key={idx}
                  data-aos={`${val % 2 === 0 ? "fade-up" : "fade-down"}`}
                  data-aos-duration="1800"
                >
                  <TeamCards
                    Photo={el.Photo}
                    Name={el.Name}
                    Job={el.Job}
                    Linkedin={el.Linkedin}
                  />
                </Col>
              );
            })
          ) : (
            <></>
          )}
        </Row>
        <Col>
          <h1 className="title-team m-0">
          OUR COMMUNITY <span className="team-gradient">VULUNTEERS</span>
          </h1>
        </Col>

        <Row className="d-flex justify-content-center py-5">
          {ComunityVolunteers ? (
            ComunityVolunteers.map((el, idx) => {
              const val = contador;
              contador++;
              return (
                <Col
                  xs="9"
                  md="7"
                  lg="5"
                  xl="3"
                  className="mx-3 my-5"
                  key={idx}
                  data-aos={`${val % 2 === 0 ? "fade-up" : "fade-down"}`}
                  data-aos-duration="1800"
                >
                  <TeamCards
                    Photo={el.Photo}
                    Name={el.Name}
                    Job={el.Job}
                    Linkedin={el.Linkedin}
                  />
                </Col>
              );
            })
          ) : (
            <></>
          )}
        </Row>
      </Container>
    </div>
  );
};

export default Team;
