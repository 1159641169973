import React from "react";

const RolesCards = ({ title, content, buttonTitle }) => {
  return (
    <div id="RolesCards">
      <div className="card-content">
        <div>
          <p className="card-title text-white">{title}</p>

          <div>
            <p className="card-text">{content}</p>
          </div>
        </div>

        <div className="button-card">
          <a href="/"> {buttonTitle} </a>
        </div>
      </div>
    </div>
  );
};

export default RolesCards;
