import React from "react";
import { Row, Col, Container } from "reactstrap";

const Play = () => {
  return (
    <div id="Play">
      <Container>
        <Row className="d-flex justify-content-center py-5">
          <Col
            xs="11"
            md="10"
            xl="6"
            className="d-flex justify-content-center align-items-start pt-4"
          >
            <div className="content-play">
              <h1
                className="title-play m-0"
                data-aos="fade-right"
                data-aos-duration="2000"
              >
                COME <span className="play-gradient">PLAY</span> WITH US
              </h1>

              <div data-aos="fade-right" data-aos-duration="2300">
                <p className="pt-4 text">
                  Venture into the heart of our solar system with Stellar Gate,
                  the ultimate online gaming experience that brings the thrill
                  of interplanetary battles to life! We invite you to join our
                  diverse community of players as you journey through the
                  majestic realms of the solar system, engaging in fierce
                  skirmishes on the surfaces of iconic planets and celestial
                  bodies. Immerse yourself in our rich, interactive worlds where
                  unforgettable encounters and exhilarating challenges await you
                  on Mars, Jupiter, and beyond.
                </p>

                <p className="pt-4 text">
                  Whether you're a battle-hardened space warrior or a curious
                  cosmic explorer, Stellar Gate offers something for everyone.
                  So, don your space armor, fuel up your starship, and prepare
                  to embark on an epic odyssey full of adrenaline-pumping
                  action, camaraderie, and wonder. The solar system is your
                  battleground—come and join us in Stellar Gate today!
                </p>
              </div>

              {/* <div
                className="gradient-button pt-5"
                data-aos="fade-right"
                data-aos-duration="2600"
              >
                <a href="/AboutUs">GET STARTED</a>
              </div> */}
            </div>
          </Col>
          <Col xs="11" md="10" xl="6" className="mx-auto py-4">
            <div className="content-play m-0">
              <div data-aos="fade-left" data-aos-duration="2000">
                <div className="bars-container">
                  <span className="bar"></span>
                  <span className="point"></span>
                </div>
                <h3>First Person Shooter</h3>
                <p className="text">
                  Stellar Gate delivers a heart-pounding first-person shooting
                  experience, allowing you to showcase your combat skills on any
                  planet within the solar system. From the rugged terrain of
                  Mars to the icy landscapes of Europa, each battlefield offers
                  a unique and dynamic environment that will put your strategic
                  thinking and reflexes to the test.
                </p>
              </div>

              <div data-aos="fade-left" data-aos-duration="2300">
                <div className="bars-container">
                  <span className="bar"></span>
                  <span className="point"></span>
                </div>
                <h3>Real Time Strategy</h3>
                <p className="text">
                  Stellar Gate takes real-time strategy to new heights, giving
                  you the opportunity to command live players and assume the
                  role of a fleet commander. As the architect of your team's
                  success, you'll have the power to make crucial decisions that
                  directly impact the outcome of every battle.
                </p>
              </div>

              <div data-aos="fade-left" data-aos-duration="2600">
                <div className="bars-container">
                  <span className="bar"></span>
                  <span className="point"></span>
                </div>
                <h3>Network</h3>
                <p className="text">
                  Playing with friends or joining forces with a clan amplifies
                  the fun and excitement of Stellar Gate. Coordinate with your
                  teammates to devise masterful battle strategies, flank your
                  enemies, and conquer the solar system together. Forge
                  unbreakable bonds as you celebrate victories, learn from
                  defeats, and climb the ranks to establish your clan's
                  dominance.
                </p>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Play;
