import React, { useState, useEffect } from "react";
import { Row, Col } from "reactstrap";
import { ethers } from "ethers";

//React Scroll Parallax
import { Parallax } from "react-scroll-parallax";

//gate
import gate from "../../assets/Banner/gate.png";

//Glares
import glare1 from "../../assets/Banner/glares/glare1.png";
import glare2 from "../../assets/Banner/glares/glare2.png";
import glare3 from "../../assets/Banner/glares/glare3.png";
import glare4 from "../../assets/Banner/glares/glare4.png";
import glare5 from "../../assets/Banner/glares/glare5.png";
import glare6 from "../../assets/Banner/glares/glare6.png";
import glare7 from "../../assets/Banner/glares/glare7.png";
import glare8 from "../../assets/Banner/glares/glare8.png";
import glare9 from "../../assets/Banner/glares/glare9.png";
import glare10 from "../../assets/Banner/glares/glare10.png";
import glare11 from "../../assets/Banner/glares/glare11.png";

//Stars
import star1 from "../../assets/Banner/stars/star1.png";
import star2 from "../../assets/Banner/stars/star2.png";
import star3 from "../../assets/Banner/stars/star3.png";
import star4 from "../../assets/Banner/stars/star4.png";

//Collab companies
import Oasis from "../../assets/Banner/OASIS.png";
import Tarb from "../../assets/Banner/TARB.png";

const Banner = () => {
  const [walletAddress, setWalletAddress] = useState("");
  const [registrationStatus, setRegistrationStatus] = useState("");
  const [isRegistered, setIsRegistered] = useState(false);

  const contractAddress = "0xC6848846dB11D5C53Ae76AA047524b045bedC7B5";
  const contractABI = [
    "function registerWallet() external",
    "function isWalletRegistered(address wallet) external view returns (bool)"
  ];

  const arbitrumChainId = "0xa4b1"; // Arbitrum One Mainnet

  const switchToArbitrum = async () => {
    try {
      await window.ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: arbitrumChainId }],
      });
      return true;
    } catch (switchError) {
      // This error code indicates that the chain has not been added to MetaMask.
      if (switchError.code === 4902) {
        try {
          await window.ethereum.request({
            method: 'wallet_addEthereumChain',
            params: [{
              chainId: arbitrumChainId,
              chainName: 'Arbitrum One',
              nativeCurrency: {
                name: 'Ethereum',
                symbol: 'ETH',
                decimals: 18
              },
              rpcUrls: ['https://arb1.arbitrum.io/rpc'],
              blockExplorerUrls: ['https://arbiscan.io/']
            }],
          });
          return true;
        } catch (addError) {
          console.error('Failed to add Arbitrum network:', addError);
          setRegistrationStatus('Failed to add Arbitrum network. Please add it manually in MetaMask.');
          return false;
        }
      }
      console.error('Failed to switch to Arbitrum network:', switchError);
      setRegistrationStatus('Failed to switch to Arbitrum network. Please switch manually in MetaMask.');
      return false;
    }
  };

  const connectWalletAndRegister = async () => {
    if (typeof window.ethereum !== 'undefined') {
      try {
        await window.ethereum.request({ method: 'eth_requestAccounts' });
        let provider = new ethers.BrowserProvider(window.ethereum);
        
        const network = await provider.getNetwork();
        if (network.chainId.toString(16) !== arbitrumChainId.slice(2)) {
          const switched = await switchToArbitrum();
          if (!switched) return;
          
          // Reinitialize the provider after network switch
          provider = new ethers.BrowserProvider(window.ethereum);
        }
  
        const signer = await provider.getSigner();
        const address = await signer.getAddress();
        setWalletAddress(address);
        console.log("Wallet connected:", address);
  
        const contract = new ethers.Contract(contractAddress, contractABI, signer);
        console.log("Contract instance created");
  
        // Function to check registration and interact with the contract
        const checkAndRegister = async () => {
          console.log("Checking if wallet is registered...");
          try {
            const walletIsRegistered = await contract.isWalletRegistered(address);
            console.log("Is wallet registered:", walletIsRegistered);
  
            if (walletIsRegistered) {
              setIsRegistered(true);
              setRegistrationStatus("Your wallet is already registered. Welcome back!");
            } else {
              console.log("Wallet not registered. Attempting to register...");
              setRegistrationStatus("Registration in progress..."); // Immediate feedback
              const tx = await contract.registerWallet();
              console.log("Registration transaction sent:", tx.hash);
              setRegistrationStatus("Registration transaction sent. Waiting for confirmation...");
              const receipt = await tx.wait();
              console.log("Registration transaction confirmed. Receipt:", receipt);
              setIsRegistered(true);
              setRegistrationStatus("Registration successful");
            }
          } catch (error) {
            console.error("Error in contract interaction:", error);
            
            if (error.code === 'BAD_DATA' && error.value === '0x') {
              console.log("No registrations yet. Attempting to register this wallet.");
              try {
                setRegistrationStatus("Registration in progress..."); // Immediate feedback
                const tx = await contract.registerWallet();
                console.log("Registration transaction sent:", tx.hash);
                setRegistrationStatus("Registration transaction sent. Waiting for confirmation...");
                const receipt = await tx.wait();
                console.log("Registration transaction confirmed. Receipt:", receipt);
                setIsRegistered(true);
                setRegistrationStatus("Registration successful");
              } catch (registrationError) {
                console.error("Error during registration:", registrationError);
                setRegistrationStatus(`Failed to register wallet: ${registrationError.message}`);
              }
            } else {
              setRegistrationStatus(`Failed to interact with contract: ${error.message}`);
            }
          }
        };
  
        // Check and register initially
        await checkAndRegister();
  
        // Listen for network changes
        window.ethereum.on('chainChanged', async (chainId) => {
          if (chainId === arbitrumChainId) {
            console.log("Switched to Arbitrum. Rechecking registration...");
            await checkAndRegister();
          } else {
            setRegistrationStatus("Please switch to Arbitrum network to interact with the contract.");
          }
        });
  
      } catch (error) {
        console.error("Failed to connect wallet or register:", error);
        setRegistrationStatus(`Failed to connect wallet: ${error.message}`);
      }
    } else {
      console.log("Please install MetaMask!");
      setRegistrationStatus("MetaMask not detected. Please install MetaMask to connect and register.");
    }
  };

  useEffect(() => {
    return () => {
      if (window.ethereum) {
        window.ethereum.removeAllListeners('chainChanged');
      }
    };
  }, []);

  return (
    <div id="Banner">
      <div className="banner-elements-container">
        <Parallax speed={-15} className="Parallax h-100">
          <img src={gate} className="planet" alt="planet" />
        </Parallax>

        <Parallax
          translateX={["-100px", "0px"]}
          speed={-10}
          className="Parallax h-100"
        >
          <img className="glare one" src={glare1} alt="glare" />
        </Parallax>

        <Parallax speed={-10} className="Parallax h-100">
          <img className="glare two" src={glare2} alt="glare" />
        </Parallax>

        <Parallax
          translateX={["-100px", "0px"]}
          speed={-10}
          className="Parallax h-100"
        >
          <img className="glare three" src={glare3} alt="glare" />
        </Parallax>

        <Parallax
          translateX={["-200px", "0px"]}
          speed={5}
          className="Parallax h-100"
        >
          <img className="glare four" src={glare4} alt="glare" />
        </Parallax>

        <Parallax speed={-25} className="Parallax h-100">
          <img className="glare five" src={glare5} alt="glare" />
        </Parallax>

        <Parallax speed={-5} className="Parallax h-100">
          <img className="glare six" src={glare6} alt="glare" />
        </Parallax>

        <Parallax
          translateX={["-300px", "0px"]}
          speed={-10}
          className="Parallax h-100"
        >
          <img className="glare seven" src={glare7} alt="glare" />
        </Parallax>

        <Parallax
          translateX={["100px", "100px"]}
          speed={-10}
          className="Parallax h-100"
        >
          <img className="glare eight" src={glare8} alt="glare" />
        </Parallax>

        <Parallax speed={-35} className="Parallax h-100">
          <img className="glare nine" src={glare9} alt="glare" />
        </Parallax>

        <Parallax speed={-15} className="Parallax h-100">
          <img className="glare ten" src={glare10} alt="glare" />
        </Parallax>

        <Parallax speed={-5} className="Parallax h-100">
          <img className="glare eleven" src={glare11} alt="glare" />
        </Parallax>

        <Parallax speed={-10} className="Parallax h-100">
          <img className="star one" src={star2} alt="start" />
        </Parallax>

        <Parallax
          translateX={["-100px", "0px"]}
          speed={-10}
          className="Parallax h-100"
        >
          <img className="star two" src={star3} alt="start" />
        </Parallax>

        <Parallax
          translateX={["200px", "0px"]}
          speed={-10}
          className="Parallax h-100"
        >
          <img className="star three" src={star1} alt="start" />
        </Parallax>

        <Parallax speed={10} className="Parallax h-100">
          <img className="star four" src={star4} alt="start" />
        </Parallax>
      </div>
      <Row className="gap-4">
        <div className="herobanner z-1"></div>
        <Col lg="12" data-aos="fade-up" data-aos-duration="1200">
          <h1 className="title-banner text-center">
            ANOMALY SERIES - <span className="new">ENLIST NOW!</span>
          </h1>
        </Col>

        <Col xs="11" lg="7" className="d-flex justify-content-center mx-auto">
          <p
            className="text-center text-banner"
            data-aos="fade-up"
            data-aos-duration="1500"
          >
            AND COMPETE FOR YOUR SHARE OF 2500 ARB AND 200,000 TAZZ!
          </p>
        </Col>

        <Col
          lg="7"
          className="d-flex justify-content-center mx-auto"
          data-aos="fade-up"
          data-aos-duration="2000"
        >
          <div className="gradient-button large-button">
            <a href="#" onClick={connectWalletAndRegister}>
              {isRegistered 
                ? "Wallet Registered" 
                : (walletAddress 
                  ? `Connected: ${walletAddress.substring(0, 6)}...${walletAddress.substring(38)}` 
                  : "Connect & Register")}
            </a>
          </div>
        </Col>

        {registrationStatus && (
          <Col lg="7" className="d-flex justify-content-center mx-auto mt-3">
            <p className="registration-status">{registrationStatus}</p>
          </Col>
        )}

        <Col xs="12">
          <div className="right-aligned-content-container" data-aos="fade-up" data-aos-duration="1500">
            <div className="right-aligned-text-wrapper">
              <p className="text-banner right-aligned-text">
                War has come to Mars, and fortune calls. Put your RTS skills to the test in our first competitive e-sports series.
              </p>
              
              <div className="collaboration-section">
                <p className="text-banner">Created in collaboration with:</p>
                <div className="collaboration-logos-container">
                  <img src={Oasis} alt="Oasis" className="collaboration-logo" />
                  <img src={Tarb} alt="Tarb" className="collaboration-logo" />
                </div>
              </div>

              <div className="schedule-section">
                <p className="text-banner">Schedule:</p>
                <p className="text-banner">
                  Battle 1: 25th September (9 AM EST)<br />
                  Battle 2: 26nd September (9 AM EST)<br />
                  Battle 3: 27th September (9 AM EST)<br />
                  Battle 4: 4th October (9 AM EST)
                </p>
              </div>

              <div className="buttons-container">
                <div className="gradient-button small-button">
                  <a href="https://x.com/stellargate_io/status/1833234749755953336?s=46&t=XKeO9f8Sb-LWjFYT9AVFQQ" target="_blank" rel="noopener noreferrer">Link to X Post</a>
                </div>
                <div className="gradient-button small-button">
                  <a href="https://thankarb.com/contributions/1475" target="_blank" rel="noopener noreferrer">Thank ARB Page</a>
                </div>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Banner;