import React, { useContext } from "react";
import { Container } from "reactstrap";
import { MainContext } from "../../context/MainContext";
import Layout from "../../reusable/layout/Layout";

//Components
import Banner from "../../components/roadmap/Banner";
import Products from "../../components/roadmap/Products";

const Home = () => {
  const context = useContext(MainContext);
  if (!context) {
    console.log("useContext must be used within a TaskContextProvider");
  }

  return (
    <Layout>
      <section id="Road-map">
        <Container className="h-100">
          <Banner/>
          <Products/>
        </Container>
      </section>
    </Layout>
  );
};

export default Home;