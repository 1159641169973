import React from "react";
import { Row, Col, Container } from "reactstrap";

//Assets
import BSC from "../../assets/logo/BCSLogo.png";
import PKS from "../../assets/logo/PCSLogo.png";
import SOUL from "../../assets/logo/SOUL-logo.png";
import KCAL from "../../assets/logo/KCAL-logo.png";

const CITLaunch = () => {
  return (
    <>
      <div id="Launch">
        <Container>
          <Row>
          <Col lg="12" className="d-flex justify-content-center mt-5">
              <span className="launching-text text-center">Tradeable with soul or KCAL:</span>
            </Col>
             <Col className="d-flex justify-content-around" xs="12">
              <a href="https://bscscan.com/token/0x298Eff8af1ecEbbB2c034eaA3b9a5d0Cc56c59CD">
                <img
                  data-aos="fade-right"
                  data-aos-duration="1800"
                  className="logoCS"
                  src={SOUL}
                  alt="SOUL logo"
                  />
              </a>
              <a href="https://bscscan.com/token/0x855EA8048E1852996429A50aBdA60F583909d298">
                <img
                  data-aos="fade-left"
                  data-aos-duration="1800"
                  className="logoCSP"
                  src={KCAL}
                  alt="KCAL logo"
                />
              </a>
            </Col>
          </Row>
          <Row>            
            <Col lg="12" className="d-flex justify-content-center mt-5">
              <span className="launching-text">Launch in:</span>
            </Col>
            <Col className="logos-container my-5" xs="12">
              <img
                data-aos="fade-right"
                data-aos-duration="1800"
                className="logo"
                src={BSC}
                alt="Binance logo"
              />
              <img
                data-aos="fade-left"
                data-aos-duration="1800"
                className="logo"
                src={PKS}
                alt="Pancake logo"
              />
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default CITLaunch;
