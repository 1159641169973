import React from "react";
import { Container } from "reactstrap";
import Layout from "../../reusable/layout/Layout";
import { useState, useEffect } from "react";
import winLogo from "../../assets/logo/Windows.png"
import macLogo from "../../assets/logo/MacOs.png"

const Game = () => {
  return (
    <Layout>
      <section id="Game">
        <div className="layer h-100 d-flex flex-column align-items-center justify-content-center">
            <h1 className="text-light title-game mb-5">Stellar Gate Battle Launcher Still Under Construction</h1>
            <div className="d-flex w-75 justify-content-around flex-wrap">
              <div className="gradient-button text-nowrap mt-5">
                <img src={winLogo} className="plat-logo-w" alt="" />
                <div>
                  <a href="#">Download for Windows (coming soon)</a>
                </div>
              </div>
              <div className="gradient-button text-nowrap mt-5">
                <img src={macLogo} className="plat-logo-m" alt="" />
                <div>
                  <a href="#">Download for MacOS (coming soon)</a>
                </div>
              </div>
            </div>
            <h6 className="disclaimer-text">This is a work in progress. All work is not final</h6>
            <h6 className="disclaimer-text">Game is not fully completed yet, use at your own risk. Download as is</h6>
        </div>
        <div className="bg-image h-100">
        </div>
      </section>
    </Layout>
  );
};

export default Game;
