import React from "react";
import { Col } from "reactstrap";

//Social media
import LinkedinImg from "../../../assets/social-media/Linkedin.png";

const TeamCards = ({ Photo, Name, Job, Linkedin }) => {
  return (
    <div id="TeamCards">
      <div className="card-content">
        <div className="photo">
          <img src={Photo} alt="Team" />
        </div>

        <div className="px-4">
          <p className="name">{Name}</p>

          <div>
            <p className="job text-white my-2">{Job}</p>
          </div>
          <Col lg="12" className="d-flex my-4">
            <div className="me-3">
              <a href={Linkedin} target="blank">
                <img
                  className="linkedinImg"
                  src={LinkedinImg}
                  alt="LinkedinImg"
                />
              </a>
            </div>
          </Col>
        </div>
      </div>
    </div>
  );
};

export default TeamCards;
