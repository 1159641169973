import React, { useState } from "react";
import { Row, Col } from "reactstrap";

const WalletItems = ({ title, icon, value, checkicon }) => {
  const [active, setActive] = useState({
    btn1: false,
    btn2: false,
  });

  const toggleElement = (modal) => {
    setActive((prevState) => {
      const newState = {};
      for (const key in prevState) {
        if (key === modal) {
          newState[key] = !prevState[key];
        } else {
          newState[key] = false;
        }
      }
      return newState;
    });
  };

  return (
    <div
      id="WalletItems"
      onClick={() => toggleElement(`btn${value}`)}
      className={`${
        active[`btn${value !== "" ? value : "0"}`] ? "active" : ""
      }`}
    >
      <Row className="card-content">
        <Col
          xs="1"
          className="d-flex align-items-center justify-content-center p-0"
        >
          <img className="icon" src={icon} alt="icon" />
        </Col>
        <Col xs="11" className="d-flex align-items-center">
          <div>
            <p className="card-title text-white">{title}</p>

            <img
              className={`check-icon ${
                active[`btn${value !== "" ? value : "0"}`] ? "active" : ""
              }`}
              src={checkicon}
              alt="check"
            />
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default WalletItems;
