import React, { useContext } from "react";
import { Container } from "reactstrap";
import { MainContext } from "../../context/MainContext";
import Layout from "../../reusable/layout/Layout";

//Components
import Banner from "../../components/home/Banner";
import Future from "../../components/home/Future";
import Projects from "../../components/home/Projects";
import Experience from "../../components/home/Experience";
import JoinUs from "../../components/home/JoinUs";
import Play from "../../components/home/Play";

const Home = () => {
  const context = useContext(MainContext);
  if (!context) {
    console.log("useContext must be used within a TaskContextProvider");
  }

  return (
    <Layout>
      <section id="Home">
        <Container className="h-100">
          <Banner />
          <Projects />
          <Future />
        </Container>
        <Container className="h-100">
          <Experience />
          <Play />
          <JoinUs />
        </Container>
      </section>
    </Layout>
  );
};

export default Home;
