import React from "react";
import { Container } from "reactstrap";
import Layout from "../../reusable/layout/Layout";

//Components
import Banner from "../../components/policy/Banner";
import Privacy from "../../components/policy/Privacy";

const Policy = () => {
  return (
    <Layout>
      <section id="Policy">
        <Container className="h-100">
          <Banner />
          <Privacy />
        </Container>
      </section>
    </Layout>
  );
};

export default Policy;
