import React from "react";
import { Row, Col } from "reactstrap";

//React Scroll Parallax
import { Parallax } from "react-scroll-parallax";

//Imgs
import circles from "../../assets/bg-images/circles.png";
import star from "../../assets/bg-images/star-img.png";

const Banner = () => {
  return (
    <>
      <img className="bg-circles" src={circles} alt="circles" />
      <div id="Banner">
        <Parallax
          translateX={["1000px", "30px"]}
          speed={-10}
          className="Parallax h-100"
        >
          <img className="star one" src={star} alt="start" />
        </Parallax>

        <Parallax
          translateX={["1000px", "0px"]}
          speed={-23}
          className="Parallax h-100"
        >
          <img className="star two" src={star} alt="start" />
        </Parallax>
        <Row className="gap-4">
          <Col lg="12" data-aos="fade-up" data-aos-duration="1200">
            <h1 className="title-banner text-center m-0">
              ROAD-<span className="map">MAP</span>
            </h1>
          </Col>

          <Col xs="11" lg="7" className="d-flex justify-content-center mx-auto">
            <p
              className="text-center"
              data-aos="fade-up"
              data-aos-duration="1500"
            >
              Follow our roadmap! We will be releasing parts of the game as we
              push forward to the full release and more! All milestones may
              change.
            </p>
          </Col>

          <Col lg="12" data-aos="fade-up" data-aos-duration="1800">
            <div className="arrow-container">
              <a href="#Products">
                <div className="arrow"></div>
              </a>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default Banner;
