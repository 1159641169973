import React from "react";

const CardItems = ({
  icon,
  title,
  subTitle,
  content,
  buttonTitle,
  buttonReference,
}) => {
  return (
    <div id="CardItems">
      <div className="card-content">
        <div className="px-4">
          <div className="text-center icon-crypto">
            <img src={icon} alt="icon" />
          </div>
          <p className="card-title text-center text-white">{title}</p>

          <p className="card-sub-title text-center">{subTitle}</p>

          <div className="text-center">
            <p className="card-text">{content}</p>
          </div>
        </div>

        <a className="button-card mx-auto" href={buttonReference}>
          <div>{buttonTitle}</div>
        </a>
      </div>
    </div>
  );
};

export default CardItems;
