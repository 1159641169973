import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "reactstrap";
import { Link } from "react-router-dom";
import logo from "../../assets/logo/stellarlogo.png";
import logo2 from "../../assets/logo/logostext.png";
import bars from "../../assets/svg/bars-solid-white.svg";
import { useLocation } from "react-router-dom";
import WalletItems from "./items/WalletItems";

//walletIcons

import poltergeist from "../../assets/img/poltergeist.png";
import ectoWallet from "../../assets/img/ectowallet.png";

//Social Media
import facebook from "../../assets/social-media/brandico_facebook-rect.png";
import discord from "../../assets/social-media/logos_discord-icon.png";
import twitter from "../../assets/social-media/X.png";
import telegram from "../../assets/social-media/logos_telegram.png";
import instagram from "../../assets/social-media/instagram.png";
import linktree from "../../assets/social-media/linktree.png";
import whatsapp from "../../assets/social-media/whatsapp.png";

//X button
import X from "../../assets/arrow/Cross.png";

//check Icon
import checkIcon from "../../assets/arrow/Check.png";

const Header = () => {
  const [scrollPosition, setScrollPosition] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      setScrollPosition(window.scrollY);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const [burguer, setBurguer] = useState(false);
  const [modal, setModal] = useState(false);

  const location = useLocation();

  const handleContentChange = () => {
    // Obtener la ruta actual
    const currentPath = location.pathname;

    // Condicionalmente cambiar el contenido basado en la ruta actual
    if (
      currentPath === "/" ||
      currentPath === "/TokenLaunch" ||
      currentPath === "/Game/"
    ) {
      return "transparent";
    } else {
      return "gray";
    }
  };

  const walletItems = [
    {
      checkicon: checkIcon,
      icon: ectoWallet,
      title: "EctoWallet - UNDER CONSTRUCTION",
    },

    {
      checkicon: checkIcon,
      icon: poltergeist,
      title: "Poltergeist - UNDER CONSTRUCTION",
    },
  ];

  return (
    <>
      <section
        id="Header"
        className={`py-3 ${handleContentChange()} ${
          scrollPosition >= 80 ? "navbar-blur" : ""
        }`}
      >
        <Container>
          <Row className="d-flex justify-content-evenly">
            <Col xs="3" md="1" lg="3" xl="3" className="p-0">
              <div className="d-flex justify-content-center">
                <a href="/">
                  <img src={logo} className="logo" alt="Stellar-Gate Logo" />
                </a>
                <a href="/">
                  <img className="logo-solo" src={logo2} alt="logo" />
                </a>
              </div>
            </Col>
            <Col
              xs="5"
              md="8"
              lg="6"
              xl="6"
              className={`p-0 list-container ${
                burguer ? "burguer-active" : ""
              }`}
            >
              <ul className="navList p-0 m-0">
                {/* 
                <li>
                  <Link to="/roadmap">
                    <span className="Navlink p-0">Road-Map</span>
                  </Link>
                </li>
                */}
                <li>
                  <Link to="/Whitepaper">
                    <span className="Navlink">Whitepaper</span>
                  </Link>
                </li>
                {/* 
                <li className="contractContainer">
                  <Link to="/Game/">
                      <span className="contract ps-0 pe-3" data-text="Launcher">
                        Launcher
                    </span>
                  </Link>
                </li>
                */}
                {/* 
                  <li>
                    <Link to="/TokenLaunch">
                      <span className="Navlink">Tokenomics</span>
                    </Link>
                  </li>
                  */}

                <li>
                  <Link to="/aboutUs">
                    <span className="Navlink ps-0">About Us</span>
                  </Link>
                </li>

                <div className="social-media-container">
                  <a
                    target="blank"
                    href="https://www.instagram.com/stellargate_io/"
                  >
                    <img
                      src={instagram}
                      alt="instagram"
                      className="social-media"
                    />
                  </a>

                  <a target="blank" href="https://twitter.com/stellargate_io">
                    <img src={twitter} alt="twitter" className="social-media" />
                  </a>

                  <a target="blank" href="https://discord.gg/vV6Evg2FCY">
                    <img src={discord} alt="discord" className="social-media" />
                  </a>

                  <a
                    target="blank"
                    href="https://www.facebook.com/stellargatego"
                  >
                    <img
                      src={facebook}
                      alt="facebook"
                      className="social-media"
                    />
                  </a>

                  <a target="blank" href="https://t.me/stellargateio">
                    <img
                      src={telegram}
                      alt="telegram"
                      className="social-media"
                    />
                  </a>

                  <a target="blank" href="https://linktr.ee/stellar_gate">
                    <img
                      src={linktree}
                      alt="linktree"
                      className="social-media ps-0"
                    />
                  </a>

                  <a
                    target="blank"
                    href="https://chat.whatsapp.com/EPuENlYhOBZC3HYriBB8wG"
                  >
                    <img
                      src={whatsapp}
                      alt="whatsapp"
                      className="social-media ps-0"
                    />
                  </a>
                </div>
              </ul>
            </Col>

            <Col
              xs="6"
              md="3"
              lg="3"
              xl="3"
              className="p-0 d-flex align-items-center justify-content-center"
            >
              <div className="button-wallet">
                <a href="https://soc.stellargate.io">Enter SOC dApp</a>
              </div>
            </Col>

            <div
              className={`modal-wallet-wrapper ${
                modal ? "modal-wallet-active" : ""
              }`}
            >
              <div className="modal-wallet">
                <img
                  onClick={() => setModal(!modal)}
                  src={X}
                  alt="x button"
                  className="close-button"
                />
                <h3 className="connect-wallet-title text-white">
                  Connect your wallet
                </h3>
                <div className="line"></div>

                <h3 className="connect-wallet-subtitle">Choose Wallet</h3>

                <div className="d-flex flex-wrap justify-content-center pt-3">
                  {walletItems ? (
                    walletItems.map((el, idx) => {
                      return (
                        <Col xs="12" className="p-2" key={idx}>
                          <WalletItems
                            title={el.title}
                            icon={el.icon}
                            value={idx + 1}
                            checkicon={el.checkicon}
                          />
                        </Col>
                      );
                    })
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>
            <Col xs="3" className="bars-container">
              <img
                onClick={() => setBurguer(!burguer)}
                src={bars}
                alt="icon"
                className="bars"
              />
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default Header;
