import React from "react";
import { Container, Row, Col } from "reactstrap";
import ship from "../../assets/bg-images/ship.png";
import text from "../../assets/bg-images/stellargate-text.png";

const AboutUs = () => {
  return (
    <div id="Banner">
      <Container>
        <Row>
          <div className="bg-stars"></div>
          <Col xs="12" data-aos="fade-up" data-aos-duration="1200">
            <div className="img-container">
              <div className="planet-wrapper">
                <div className="img-wrapper">
                  <img className="planet" src={ship} alt="planet" />
                </div>
                <img className="text" src={text} alt="text" />
                <h1 className="title">ABOUT US</h1>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default AboutUs;
