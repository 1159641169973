import React from "react";

const ProductItems = ({
  circle,
  shape,
  title,
  CurrentState,
  bar,
  subtitle1,
  content1a,
  content1b,
  subtitle2,
  content2a,
  content2b,
  content2c,
  content2d,
}) => {
  return (
    <div id="ProductItems">
      <div className="card-content">
        <div>
          <img className="circle" src={circle} alt="circle" />
          <div className="phase-container">
            <img className="shape" src={shape} alt="shape" />
            <p className="card-title m-0">
              {title}
              <p className="done">{CurrentState}</p>
            </p>
          </div>

          <div className="card-text-container">
            <div className="card-part">
              <img className="bar" src={bar} alt="bar" />
              <p className="card-subtitle">{subtitle1}</p>
              <p className="card-text">{content1a}</p>
              <p className="card-text">{content1b}</p>
            </div>

            <div className="card-part">
              <p className="card-subtitle">{subtitle2}</p>
              <p className="card-text">{content2a}</p>
              <p className="card-text">{content2b}</p>
              <p className="card-text">{content2c}</p>
              <p className="card-text">{content2d}</p>
              <img className="bar2" src={bar} alt="bar" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductItems;
