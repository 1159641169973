//React assets
import React from "react";
import ReactDOM from "react-dom/client";
//Bootstrap
import "bootstrap/dist/css/bootstrap.css";
//React Router Dom
import { BrowserRouter } from "react-router-dom";
//Component app
import App from "./App";
//MainContext
import { MainContextProvider } from "./context/MainContext";
//Parallax Provider
import { ParallaxProvider } from 'react-scroll-parallax';

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <ParallaxProvider>
      <MainContextProvider>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </MainContextProvider>
    </ParallaxProvider>
  </React.StrictMode>
);
