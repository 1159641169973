import React from "react";

const ProjectItems = ({ img, title, subTitle, content, buttonTitle, link }) => {
  return (
    <div id="ProjectItems">
      <div className="card-content">
        <div>
          <div className="text-center img-project">
            {buttonTitle ? 
            <img src={img} alt="img"  width="250"/>
            :
            <iframe width="460" height="270" src={link} title="Stellar Gate - Early access demo - Version 0.0.4.2.3" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
          }
          </div>
          <p className="card-sub-title">{title}</p>

          <p className="card-title text-white">{subTitle}</p>

          <div>
            <p className="card-text">{content}</p>
          </div>
          <br />
        </div>
        { buttonTitle ? 
          <div className="button-card">
            <a target="" href={link}>
              {" "}
              {buttonTitle}{" "}
            </a>
          </div>
          :
          ""
        }
      </div>
    </div>
  );
};

export default ProjectItems;
