import React from "react";
import { Row, Col, Container } from "reactstrap";
import RolesCards from "./RolesCards/RolesCards";

const Team = () => {
  let contador = 0;

  const RolesContent = [
    {
      title: "Jr. Gate Engineer",
      content:
        "We are accepting anyone willing to assist in moderating our discord server!",
      buttonTitle: "Coming soon",
    },

    {
      title: "Jr. Marketing",
      content: "Think you can moon Stellar Gate? Then contact us!",
      buttonTitle: "Coming soon",
    },

    {
      title: "Jr. Developer",
      content:
        "Love coding? Awesome we do too! Join us to make this a solid game!",
      buttonTitle: "Coming soon",
    },

    {
      title: "Jr. 3D/2D Artist",
      content:
        "Love the arts? Then we look forward to bringing your best space art out there!",
      buttonTitle: "Coming soon",
    },
  ];

  return (
    <div id="Team">
      <Container>
        <Col>
          <h1 className="title-team m-0">
            CURRENT GROUP ASSISTANT <span className="team-gradient">ROLES</span>
          </h1>
        </Col>
        <Row className="d-flex justify-content-center py-5">
          {RolesContent ? (
            RolesContent.map((el, idx) => {
              const val = contador;
              contador++;
              return (
                <Col
                  xs="11"
                  md="10"
                  lg="8"
                  xl="6"
                  className="p-2"
                  key={idx}
                  data-aos={`${val % 2 === 0 ? "fade-right" : "fade-left"}`}
                  data-aos-duration={idx * 200 + 2000}
                >
                  <RolesCards
                    title={el.title}
                    content={el.content}
                    buttonTitle={el.buttonTitle}
                  />
                </Col>
              );
            })
          ) : (
            <></>
          )}
        </Row>
      </Container>
    </div>
  );
};

export default Team;
