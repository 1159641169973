import React from "react";
import { Row, Col } from "reactstrap";
import terra from "../../assets/img/terra.png";
import solar from "../../assets/img/solar.png";
import education from "../../assets/img/education.png";

import CardItems from "./cardItems/CardItems";

//wave
import wave from "../../assets/bg-images/wave.png";

const Future = () => {
  const items = [
    {
      icon: terra,
      title: "Live aboard Promethei Terra",
      subTitle: "Moon Carrier Vessel (MCV) - 01",
      content:
        "All hands on deck! Prepare to live inside Promethei Terra! Made on the Saturn Docks, it is a unified achievement by the Solar Planetary Alliance Forces (SPAF) in an effort to establish order in the Solar System.",
      buttonTitle: "More soon",
      buttonReference: "/",
    },

    {
      icon: solar,
      title: "Solar Planetary Alliance",
      subTitle: "A unified future",
      content:
        "After years of planetary battles, the Solar Planetary Alliance was formed to bring peace to the Solar System. However, once again, a new war has been brought upon its citizens.",
      buttonTitle: "More soon",
      buttonReference: "/",
    },

    {
      icon: education,
      title: "Decentralized Education Protocol",
      subTitle: "Education for All",
      content:
        "Due to the high demand for high-tech jobs and the inefficiency of classic methods of teaching, the Solar Planetary Alliance declared the creation of the Decentralized Education Protocol with the mission of educating all citizens uniformly.",
      buttonTitle: "More soon",
      buttonReference: "/",
    },
  ];

  return (
    <div id="Future">
      <img className="wave" src={wave} alt="wave" />
      <Row className="gap-4 pb-5">
        <Col lg="12">
          <h1 className="title-future text-center m-0">
            A new future with stellar gate
          </h1>
        </Col>

        <Col xs="11" lg="8" className="d-flex justify-content-center mx-auto">
          <p className="text-center">
            Stellar Gate is a first person shooter (FPS) game crossover with
            real time strategy (RTS) elements. We categorize our game within the
            play for fun spectrum powered by blockchain. Our expansive knowledge
            of games has allowed us to bring what every gamer desires, the
            ability to create your own experience.
          </p>
        </Col>
      </Row>

      <Row className="w-100 m-0 d-flex justify-content-center">
        {items ? (
          items.map((el, idx) => {
            return (
              <Col
                xs="11"
                md="8"
                lg="6"
                xl="4"
                className="pt-5"
                key={idx}
                data-aos="fade-up"
                data-aos-duration={idx * 1000 + 1000}
              >
                <CardItems
                  icon={el.icon}
                  title={el.title}
                  subTitle={el.subTitle}
                  content={el.content}
                  buttonTitle={el.buttonTitle}
                  buttonReference={el.buttonReference}
                />
              </Col>
            );
          })
        ) : (
          <></>
        )}
      </Row>
    </div>
  );
};

export default Future;
