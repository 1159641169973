import React from "react";
import { Container, Row, Col } from "reactstrap";
import working from "../../assets/img/lost-astronaut.jpg";
import Layout from "../../reusable/layout/Layout";

const NotFound = () => {
  return (
    <Layout>
      <section id="NotFound" className="py-5">
        <Container>
          <Row>
            <Col>
              <div
                className="d-flex mx-auto position-relative error-wrapper"
                data-aos="fade-left"
                data-aos-duration="1000"
              >
                <h1 className="content">Error 404!</h1>
              </div>

              <div
                className="d-flex justify-content-center"
                data-aos="fade-right"
                data-aos-duration="1300"
              >
                <h3 className="content-subtitle">this page does not exist</h3>
              </div>

              <div className="d-flex justify-content-center pt-4 px-3">
                <img className="error-img" src={working} alt="astronaut" />
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </Layout>
  );
};

export default NotFound;
